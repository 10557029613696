<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="30%"
    >
      <div>
        <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
          <el-row>
            <el-form-item label="类型" >
              <el-radio-group v-model="addEditForm.type"  @change="sellTypeButton" prop="type">
                <el-radio :label="1">常规</el-radio>
                <el-radio :label="2">活动</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="售卖名称" prop="name">
             <el-input v-model="addEditForm.name" ></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="套餐code" prop="comboFrom">
              <el-select
                @change="AddSelectDept"
                value-key="code"
                v-model="comboFrom"
                filterable
                placeholder="请选择"
                style="width:70%"
              >
                <el-option
                  v-for="item in comboList"
                  :key="item.code"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="售卖数量" prop="quantity">
              <el-input-number v-model="addEditForm.quantity"></el-input-number>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="售卖单价" prop="pricing">
              <el-input-number v-model="addEditForm.pricing"></el-input-number>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="售卖折扣" prop="discount">
              <el-input-number v-model="addEditForm.discount"></el-input-number>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="售卖开始时间" prop="startTime">
              <el-date-picker
                v-model="addEditForm.startTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择月"
              ></el-date-picker>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="售卖结束时间" prop="endTime">
              <el-date-picker
                v-model="addEditForm.endTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择月"
              ></el-date-picker>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="有效期" prop="validityTime" v-if="this.sellType==2">
              <el-date-picker
                v-model="addEditForm.validityTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择月"
              ></el-date-picker>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :md="12" :sm="12">
              <el-form-item label="自动订阅" prop="isSubscribe">
                <el-radio v-model="addEditForm.isSubscribe" :label="1">正常</el-radio>
                <el-radio v-model="addEditForm.isSubscribe" :label="2">冻结</el-radio>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12">
              <el-form-item label="状态" prop="status">
                <el-radio v-model="addEditForm.status" :label="1">正常</el-radio>
                <el-radio v-model="addEditForm.status" :label="2">冻结</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelForm">取 消</el-button>
          <!-- @click="$refs.drawer.closeDrawer()" -->
          <el-button
            type="primary"
            @click="addFormButton()"
            :loading="loading"
          >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
   selectsellByIdApi,
    deletesellByIdApi,
    editsellApi,
    addsellApi,
    listsellApi
} from "@/api/system/sell/sell.js";
import { dataListComboApi } from "@/api/system/combo/combo.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: { type:1},
      timer: null,
      ids: null,
      comboList: [],
      comboFrom: {
       
      },
      caType:1,
      sellType:1
    };
  },
  methods: {
    show(id, type) {
      console.log('tpe:{}',id)
      this.dialog = true;
      this.dataComboListButton();
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
      this.caType=type
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          // this.loading = true;
          this.cancelForm();
          this.dialog = false;
          // this.timer = setTimeout(() => {
          //   done();
          //   // 动画关闭需要一定的时间
          //   // setTimeout(() => {
          // this.loading = false;
          //   // }, 400);
          // }, 2000);
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      console.log(this.addEditForm)
      if (this.caType==1) {
        addsellApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      } 
      if(this.caType==2) {
        editsellApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton(id) {
      selectsellByIdApi(id).then(res => {
        this.addEditForm = res.result;
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    AddSelectDept(val) {
      this.addEditForm.comboCode=val.code
       this.addEditForm.comboName=val.name
    },
    dataComboListButton() {
      dataListComboApi({status:"1"}).then(res => {
        this.comboList = res.result;
      });
    },
    sellTypeButton(value){
      this.sellType=value
    }
  }
};
</script>
<style scoped>
</style>



