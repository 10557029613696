import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonSell'


const listsellApi =(params)=>getAction(baseURL+"/list",params);

const dataListSellApi =(params)=>getAction(baseURL+"/dataList",params);

const addsellApi=(params)=>postAction(baseURL+"/add",params);

const editsellApi=(params)=>putAction(baseURL+"/edit",params);

const deletesellByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectsellByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

export{
    selectsellByIdApi,
    deletesellByIdApi,
    editsellApi,
    addsellApi,
    listsellApi,
    dataListSellApi
}
