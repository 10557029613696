import { render, staticRenderFns } from "./addEditsell.vue?vue&type=template&id=c4b38d08&scoped=true&"
import script from "./addEditsell.vue?vue&type=script&lang=js&"
export * from "./addEditsell.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4b38d08",
  null
  
)

export default component.exports